// Импортируем необходимые зависимости: React, GSAP для анимаций, стили и изображения
import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './Game.css';

// Импорт изображений: фон, героя, монстра и изображения удара
import defaultBg from './img/photo_2025-03-29_00-28-37.jpg';
import heroImg from './img/photo_2025-03-28_22-23-39.png';
import monsterImg from './img/photo_2025-03-28_22-08-59.png';
import strikeImg from './img/Image19_16_12.png'; // Изображение удара

// Константа, определяющая время (в секундах) на ответ в каждом раунде
const TIME_PER_TURN = 20;

function Game() {
  /***********************
   * Состояния игры
   ***********************/
  
  // Здоровье игрока и монстра (начальное значение 200)
  const [playerHealth, setPlayerHealth] = useState(200);
  const [monsterHealth, setMonsterHealth] = useState(200);

  // Состояние, отвечающее за текущий ход: 'player' или 'monster'
  const [turn, setTurn] = useState('player');
  // Сообщения обратной связи для пользователя
  const [feedback, setFeedback] = useState('');

  /***********************
   * Очередь вопросов для таблицы умножения
   ***********************/
  
  // Формируем список фиксированных вопросов для умножения на 2 и 3
  const fixedQuestions = [];
  for (let multiplicand of [2, 3]) {
    for (let multiplier = 2; multiplier <= 9; multiplier++) {
      fixedQuestions.push({
        multiplicand,
        multiplier,
        answer: multiplicand * multiplier,
      });
    }
  }
  // Состояние очереди вопросов
  const [queue, setQueue] = useState([]);

  // Текущий вопрос – первый элемент очереди, если она не пуста
  const currentQuestion = queue.length > 0 ? queue[0] : null;
  // Состояние для вариантов ответов (6 вариантов)
  const [currentOptions, setCurrentOptions] = useState([]);

  // Таймер для отсчёта времени на ответ
  const [timer, setTimer] = useState(TIME_PER_TURN);

  /***********************
   * Работа с GSAP для анимации
   ***********************/
  
  // Ссылки на DOM-элементы героя и монстра для анимации
  const playerRef = useRef(null);
  const monsterRef = useRef(null);

  // Состояние фонового изображения (можно изменять динамически)
  const [currentBackground, setCurrentBackground] = useState(defaultBg);

  /***********************
   * Состояние для отображения удара
   ***********************/
  
  // strikeTarget: "player", "monster" или null – определяет, на каком персонаже показывать изображение удара
  const [strikeTarget, setStrikeTarget] = useState(null);

  // Функция для показа изображения удара на нужном персонаже на короткое время
  const triggerStrike = (target) => {
    setStrikeTarget(target);
    // После 300 мс изображение скрывается
    setTimeout(() => {
      setStrikeTarget(null);
    }, 300);
  };

  /***********************
   * Функции-помощники
   ***********************/
  
  // Функция перемешивания массива (алгоритм Фишера-Йетса)
  const shuffleArray = (array) => {
    const arr = array.slice();
    for (let i = arr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arr[i], arr[j]] = [arr[j], arr[i]];
    }
    return arr;
  };

  // Инициализация очереди вопросов при монтировании компонента
  useEffect(() => {
    setQueue(shuffleArray(fixedQuestions));
  }, []);

  // Функция генерации 6 вариантов ответов, включающих правильный ответ и 5 случайных
  const generateOptions = (correctAnswer) => {
    const optionsSet = new Set();
    optionsSet.add(correctAnswer);
    const range = 3;
    while (optionsSet.size < 6) {
      const offset = Math.floor(Math.random() * (range * 2 + 1)) - range;
      if (offset === 0) continue;
      optionsSet.add(correctAnswer + offset);
    }
    return Array.from(optionsSet).sort(() => Math.random() - 0.5);
  };

  // Обновление вариантов ответов и сброс таймера при изменении текущего вопроса
  useEffect(() => {
    if (currentQuestion) {
      setCurrentOptions(generateOptions(currentQuestion.answer));
      setTimer(TIME_PER_TURN);
    }
  }, [currentQuestion]);

  // Функция вибрации устройства для разных типов событий
  const vibrate = (type) => {
    if (!navigator.vibrate) return;
    if (type === 'hit') {
      navigator.vibrate(300);
    } else {
      navigator.vibrate(100);
    }
  };

  // Игра считается оконченной, если здоровье игрока или монстра меньше или равно 0
  const gameOver = playerHealth <= 0 || monsterHealth <= 0;

  // Функция для удаления текущего вопроса из очереди (при правильном ответе)
  const removeQuestion = () => {
    setQueue((prev) => {
      const newQueue = prev.slice(1);
      if (newQueue.length === 0) {
        return shuffleArray(fixedQuestions);
      }
      return newQueue;
    });
  };

  // Функция для перемещения текущего вопроса в конец очереди (при неверном ответе)
  const moveToEnd = () => {
    setQueue((prev) => {
      const [first, ...rest] = prev;
      return [...rest, first];
    });
  };

  // Обработка таймаута (когда время на ответ истекает)
  const handleTimeout = () => {
    if (!currentQuestion || gameOver) return;
    if (turn === 'player') {
      setFeedback('Время вышло! Ход переходит к монстру. Защищайся');
      vibrate('miss');
      moveToEnd();
      setTurn('monster');
    } else {
      setFeedback('Время вышло! Ты не успел отбить атаку. Монстр наносит удар. Защищайся');
      vibrate('hit');
      // Анимация удара по игроку
      if (playerRef.current) {
        gsap.fromTo(
          playerRef.current,
          { x: 0 },
          { x: -10, repeat: 3, yoyo: true, duration: 0.05 }
        );
      }
      // Отображаем изображение удара на игроке
      triggerStrike("player");
      setPlayerHealth((hp) => hp - 10);
      moveToEnd();
    }
    setTimer(TIME_PER_TURN);
  };

  // Обработка выбора ответа пользователем
  const handleAnswer = (selected) => {
    if (!currentQuestion || gameOver) return;
    setTimer(TIME_PER_TURN);

    if (turn === 'player') {
      if (selected === currentQuestion.answer) {
        setFeedback('Верно! Ты нанёс удар монстру.');
        vibrate('hit');
        // Анимация удара по монстру
        if (monsterRef.current) {
          gsap.fromTo(
            monsterRef.current,
            { x: 0 },
            { x: 10, repeat: 3, yoyo: true, duration: 0.05 }
          );
        }
        // Отображаем изображение удара на монстре
        triggerStrike("monster");
        setMonsterHealth((hp) => hp - 10);
        removeQuestion();
      } else {
        setFeedback('Неверно! Ход переходит к монстру. Защищайся');
        vibrate('miss');
        moveToEnd();
        setTurn('monster');
      }
    } else {
      // Если ход монстра – игрок должен отразить атаку
      if (selected === currentQuestion.answer) {
        setFeedback('Отлично! Вы отбили атаку. Теперь твой ход.');
        vibrate('miss');
        removeQuestion();
        setTurn('player');
      } else {
        setFeedback('Ошибка! Монстр наносит удар. Защищайся.');
        vibrate('hit');
        // Анимация удара по игроку
        if (playerRef.current) {
          gsap.fromTo(
            playerRef.current,
            { x: 0 },
            { x: -10, repeat: 3, yoyo: true, duration: 0.05 }
          );
        }
        // Отображаем изображение удара на игроке
        triggerStrike("player");
        setPlayerHealth((hp) => hp - 10);
        moveToEnd();
      }
    }
  };

  /***********************
   * Таймер обратного отсчёта
   ***********************/
  
  useEffect(() => {
    if (!gameOver && currentQuestion) {
      setTimer(TIME_PER_TURN);
      const interval = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            handleTimeout();
            return TIME_PER_TURN;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [turn, currentQuestion, gameOver]);

  /***********************
   * Сброс игры до начального состояния
   ***********************/
  
  const resetGame = () => {
    setPlayerHealth(200);
    setMonsterHealth(200);
    setTurn('player');
    setFeedback('');
    setQueue(shuffleArray(fixedQuestions));
    setTimer(TIME_PER_TURN);
  };

  /***********************
   * Пример смены фонового изображения
   ***********************/
  
  const handleChangeBackground = () => {
    console.log('Можно было бы загрузить другое изображение из базы');
  };

  /***********************
   * Рендер компонента
   ***********************/
  
  return (
    <div
      className="game-container"
      style={{
        background: `url(${currentBackground}) no-repeat center bottom`,
        backgroundSize: 'cover',
      }}
    >
      {/* Блок с персонажами: герой и монстр */}
      <div className="characters-container">
        <img ref={playerRef} className="hero-img" src={heroImg} alt="Hero" />
        <img ref={monsterRef} className="monster-img" src={monsterImg} alt="Monster" />
      </div>

      {/* Отображение изображения удара, если оно активно */}
      {strikeTarget === 'player' && (
        <img src={strikeImg} alt="Удар" className="strike strike-player" />
      )}
      {strikeTarget === 'monster' && (
        <img src={strikeImg} alt="Удар" className="strike strike-monster" />
      )}

      {/* Предупреждение для портретного режима */}
      <div className="landscape-warning">
        Пожалуйста, поверните устройство горизонтально для продолжения
      </div>

      {/* Экран окончания игры */}
      {gameOver ? (
        <div className="endgame-ui">
          <h1>{playerHealth <= 0 ? 'Вы проиграли!' : 'Ты победил монстра!'}</h1>
          <button onClick={resetGame}>Начать сначала</button>
        </div>
      ) : (
        // Основной игровой интерфейс
        <>
          <div className="health-bars-fixed">
            <div className="health-item">Игрок: {playerHealth} HP</div>
            <div className="health-item">Монстр: {monsterHealth} HP</div>
          </div>

          <div className="question-and-options">
            {feedback && (
              <div className="feedback">{feedback}</div>
            )}
            <div className="turn-row">
              <h1 className={`turn-status ${turn === 'player' ? 'player-turn' : 'monster-turn'}`}>
                Бой! {turn === 'player' ? 'Твой ход' : 'Ход монстра'}
              </h1>
              <div className="timer">Время: {timer} сек.</div>
            </div>
            <div className="question">
              {currentQuestion && (
                <>
                  {currentQuestion.multiplicand} × {currentQuestion.multiplier} = ?
                </>
              )}
            </div>
            <div className="options">
              {currentOptions.map((option, idx) => (
                <button key={idx} onClick={() => handleAnswer(option)}>
                  {option}
                </button>
              ))}
            </div>
          </div>

          <button
            style={{
              position: 'absolute',
              top: '5px',
              left: '5px',
              zIndex: 9999,
            }}
            onClick={handleChangeBackground}
          >
            Сменить фон (пример)
          </button>
        </>
      )}
    </div>
  );
}

export default Game;

