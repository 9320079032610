// AvatarSlider.jsx
import React, { useState, useRef } from 'react';
import { gsap } from 'gsap';
import './AvatarSlider.css';

// Массив путей к аватарам. Проверьте корректность путей.
const avatarImages = [
  '../../img/heros/avatar1.png',
  '../../img/heros/avatar2.png',
  '../../img/heros/avatar3.png',
  '../../img/heros/avatar4.png',
];

function AvatarSlider() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const imageRef = useRef(null);

  // Для отслеживания координат свайпа
  const touchStartX = useRef(null);
  const touchEndX = useRef(null);

  /**
   * Анимация смены аватара.
   * @param {number} newIndex - новый индекс аватара.
   * @param {number} direction - направление анимации (-1: уход влево, 1: уход вправо).
   */
  const animateChange = (newIndex, direction) => {
    gsap.to(imageRef.current, {
      duration: 0.3,
      opacity: 0,
      x: direction * 50, // смещение в зависимости от направления
      onComplete: () => {
        setCurrentIndex(newIndex);
        gsap.fromTo(
          imageRef.current,
          { opacity: 0, x: -direction * 50 },
          { duration: 0.3, opacity: 1, x: 0 }
        );
      },
    });
  };

  // Обработчики для стрелок
  const handlePrev = (direction = 1) => {
    const newIndex = currentIndex === 0 ? avatarImages.length - 1 : currentIndex - 1;
    animateChange(newIndex, direction);
  };

  const handleNext = (direction = -1) => {
    const newIndex = currentIndex === avatarImages.length - 1 ? 0 : currentIndex + 1;
    animateChange(newIndex, direction);
  };

  // Обработчики для свайпа
  const handleTouchStart = (e) => {
    touchStartX.current = e.changedTouches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    touchEndX.current = e.changedTouches[0].clientX;
    handleSwipeGesture();
  };

  const handleSwipeGesture = () => {
    if (touchStartX.current === null || touchEndX.current === null) return;
    const deltaX = touchStartX.current - touchEndX.current;
    const swipeThreshold = 50; // минимальное расстояние для определения свайпа

    if (deltaX > swipeThreshold) {
      // Свайп влево: следующий аватар, анимация сдвига влево (-1)
      handleNext(-1);
    } else if (deltaX < -swipeThreshold) {
      // Свайп вправо: предыдущий аватар, анимация сдвига вправо (1)
      handlePrev(1);
    }

    // Сброс координат
    touchStartX.current = null;
    touchEndX.current = null;
  };

  return (
    <div className="avatar-slider">
      <button className="arrow left-arrow" onClick={() => handlePrev(1)}>
        ←
      </button>
      <div
        className="avatar-image-container"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <img
          ref={imageRef}
          src={avatarImages[currentIndex]}
          alt={`Avatar ${currentIndex + 1}`}
          className="avatar-image"
        />
      </div>
      <button className="arrow right-arrow" onClick={() => handleNext(-1)}>
        →
      </button>
    </div>
  );
}

export default AvatarSlider;
