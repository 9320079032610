// App.jsx
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Game from './pages/Game';
import Contacts from './pages/Contacts'
import NotFound from './pages/NotFound'
import MainLayout from './layouts/MainLayout'
import './App.css'
import { useEffect } from 'react';

function App() {
  // Инициализация переменных для ID и имени пользователя
  let userId = null;
  let userName = null;

  if (
    window.Telegram &&
    window.Telegram.WebApp &&
    window.Telegram.WebApp.initDataUnsafe &&
    window.Telegram.WebApp.initDataUnsafe.user
  ) {
    userId = window.Telegram.WebApp.initDataUnsafe.user.id;
    userName = window.Telegram.WebApp.initDataUnsafe.user.first_name;
  }

  useEffect(() => {
    if (userId && userName) {
      // Отправляем данные на сервер
      fetch('https://api.art-web-site.ru/get_user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId, userName })
      })
        .then(response => response.json())
        .then(data => {
          console.log('Server response:', data);
        })
        .catch(error => {
          console.error('Error sending user data:', error);
        });
    }
  }, [userId, userName]);

  console.log("User ID:", userId, "User Name:", userName);

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route path="game" element={<Game />} />
            <Route path="contacts" element={<Contacts />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
