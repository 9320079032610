import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import AvatarSlider from './AvatarSlider'; // Импортируем слайдер

function Home() {
  const navigate = useNavigate();
  
  const [isLandscape, setIsLandscape] = useState(
    window.matchMedia("(orientation: landscape)").matches
  );
  
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const updateOrientation = () => {
      setIsLandscape(window.matchMedia("(orientation: landscape)").matches);
    };

    window.addEventListener("resize", updateOrientation);
    window.addEventListener("orientationchange", updateOrientation);

    async function fetchUserData() {
      if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initData) {
        const initData = window.Telegram.WebApp.initData;
        try {
          const response = await fetch("https://api.art-web-site.ru/get_user", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ initData })
          });

          if (!response.ok) {
            throw new Error("Ошибка сервера");
          }
          const data = await response.json();
          setUserInfo(data);
        } catch (error) {
          console.error("Ошибка при получении данных пользователя:", error);
        }
      } else {
        console.error("Telegram WebApp не найден или отсутствует initData");
      }
    }
    fetchUserData();

    return () => {
      window.removeEventListener("resize", updateOrientation);
      window.removeEventListener("orientationchange", updateOrientation);
    };
  }, []);

  if (!isLandscape) {
    return (
      <div className="rotate-warning">
        <h2>Пожалуйста, поверните устройство горизонтально для продолжения</h2>
      </div>
    );
  }

  const handleGameClick = () => {
    navigate("/game");
  };

  return (
    <div className="home-container">
      {userInfo && (
        <>
          <p><strong>Привет </strong> {userInfo.user}</p>
        </>
      )}
      <div className="avatar-slider-container">
        <AvatarSlider />
      </div>
      <button onClick={handleGameClick}>Перейти к игре</button>
    </div>
  );
}

export default Home;
